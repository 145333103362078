.education {
  background: #3e3e3e;
  min-height: calc(100vh + 56px);
  padding: 150px 30px;
}

.education-section {
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.education-section::-webkit-scrollbar {
  display: none;
}

.edu-card {
  background: #2d2d2d;
  height: 300px;
  min-width: 500px;
  display: flex;
  border: 1px solid #474747;
  border-radius: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 24px;
}

@media screen and (max-width: 992px) {
  .education {
    padding: 30px 15px;
    min-height: 0;
    height: auto;
  }

  .education-section {
    overflow-x: hidden;
    flex-wrap: wrap;
  }

  .edu-card {
    min-width: 0px;
    width: 100%;
    height: auto;
  }
}

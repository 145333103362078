.nav-mobile.offcanvas {
  max-width: 80% !important;
  padding: 20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.projects-canvas.offcanvas {
  background: #3e3e3e;
  color: #fff;
  height: 100% !important;
  padding: 15px !important;
}
.offcanvas-header .btn-close {
  margin-top: -40px !important;
}

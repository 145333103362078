.expertise {
  padding: 150px 30px;
  background: #343434;
  min-height: calc(100vh + 56px);
  color: #ffffff;
}

.expertise-section {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
}

.expertise-card {
  background: #2d2d2d;
  width: 300px;
  height: 400px;
  display: flex;
  border: 1px solid #474747;
  border-radius: 20px;
  flex-direction: column;
  text-align: center;
  padding: 24px;
}

@media screen and (max-width: 992px) {
  .expertise {
    padding: 30px 15px;
    min-height: 0;
    height: auto;
  }
  .expertise-section {
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .expertise-card {
    min-width: 0px;
    width: 100%;
    height: auto;
  }
}

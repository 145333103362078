.intro {
  min-height: calc(100vh + 56px);
  padding: 150px 30px;
  background: url("~/public/assets/images/ishan-right.jpg"), #1e1e1e;
  background-size: cover;
  background-position: right;
  cursor: default;
}

.image {
  height: 400px;
  border-radius: 49px;
}

.intro-container.all {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.intro-container.limited {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.hidden-text {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 992px) {
  .intro {
    padding: 30px 15px;
    min-height: 80vh;
    height: auto;
    background-size: cover;
    // background-position: left;
    background-position: 80% 30%;
  }
}

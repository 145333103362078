.work {
  padding: 150px 30px;
  background: #343434;
  min-height: calc(100vh + 56px);
  color: #ffffff;
}

.work-section {
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.work-section::-webkit-scrollbar {
  display: none;
}

.work-card {
  background: #2d2d2d;
  min-height: 550px;
  min-width: 550px;
  display: flex;
  border: 1px solid #474747;
  border-radius: 20px;
  flex-direction: column;
  padding: 24px;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .work {
    padding: 30px 15px;
    min-height: 0;
    height: auto;
  }
  .work-section {
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .work-card {
    min-width: 0;
    width: 100%;
    min-height: 0;
    height: auto;
  }
}

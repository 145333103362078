.not-found {
  background: #3e3e3e;
  padding: 40px;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(#fff, 0.7);

  .nav-home {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: #ffffff;
    background-color: #868686;
    font-family: "Lexend";
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    &:hover {
      color: #dcd0d0;
      background-color: #4c4c4c;
    }
  }
}

body:has(.not-found) .nav-bar {
  display: none;
}

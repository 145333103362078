.about {
  background: #343434;
  min-height: calc(100vh + 56px);
  padding: 150px 30px;

  .about-photo {
    border-radius: 30%;
    height: 400px;
    width: 400px;
  }
}

.flip-image {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
  touch-action: manipulation;
}

.flip-image-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  will-change: transform;
}

.flip-image:focus {
  outline: 0;
}

.flip-image:hover .flip-image-inner,
.flip-image:focus .flip-image-inner,
.flip-image:active .flip-image-inner,
.flip-image:visited .flip-image-inner {
  transform: rotateY(180deg);
}

.flip-image-front,
.flip-image-back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flip-image-front {
  z-index: 2;
  display: flex;
}

.flip-image-back {
  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .about {
    padding: 30px 15px;
    min-height: 0;
    height: auto;
    .about-photo {
      border-radius: 30%;
      height: 300px;
      width: 296px;
    }
  }
  .flip-image {
    width: 300px;
    height: 300px;
    perspective: 750px;
  }

  .flip-image-back {
    transform: rotateY(-180deg);
  }
}

@media screen and (max-width: 992px) {
  .about {
    padding: 30px 15px;
    min-height: 0;
    height: auto;
    .about-photo {
      border-radius: 30%;
      height: 200px;
      width: 200px;
    }
  }

  .flip-image {
    width: 200px;
    height: 200px;
    perspective: 500px;
  }

  .flip-image-back {
    transform: rotateY(-180deg);
  }
}

.footer {
  background: #3e3e3e;
  padding: 40px;
}

@media screen and (max-width: 992px) {
  .footer {
    padding: 15px;
    text-align: center;
    p {
      font-size: 14px !important;
    }
  }
}

.nav-bar {
  position: fixed;
  z-index: 10;
  width: 100vw;
  a {
    text-decoration: none;
  }
  .ul {
    li {
      list-style-type: none;

      a {
        text-decoration: none;
      }

      a:hover {
        color: #ffffff;
      }
    }
  }
  &:hover {
    .download-resume {
      display: flex;
    }
  }
}

.menu-link {
  font-family: "Lexend";
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  // margin-top:27px;
  text-transform: uppercase;
  // margin-bottom:27px;
  padding: 16px;
  color: #ffffff;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid white;
  }
  &:hover {
    color: #ffffff;
  }
}

.nav-bar .mobile {
  display: none !important;
  padding-top: 27px;
  padding-bottom: 27px;
  padding-right: 27px;
  span {
    color: #ffffff;
    background: rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(1px);
    border-radius: 6px;
    padding: 6px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1500px) and (min-width: 992px) {
  .menu-link {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 992px) {
  .nav-bar .desktop {
    display: none !important;
  }
  .nav-bar .mobile {
    display: flex !important;
  }
  .menu-link {
    text-decoration: none;
    line-height: 70px;
  }
}

.download-resume {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ffffff;
  background-color: #868686;
  font-family: "Lexend";
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #dcd0d0;
    background-color: #4c4c4c;
  }
}

.download-resume-mobile {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  gap: 6px;
  left: 27%;
  position: absolute;
  bottom: 100px;
  color: #ffffff;
  font-size: 20px;
  font-family: "Lexend";
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  border: 1px solid rgba(243, 243, 243, 0.1);
  text-decoration: none;
}

.contact {
  background: #3e3e3e;
  min-height: calc(95vh);
  padding: 120px 30px 40px;

  .contact-photo {
    border-radius: 30%;
    height: 60px;
  }
}

.contact-section {
  flex-flow: row wrap;
  a {
    text-decoration: none;
    color: #ffffff;
    padding: 24px;
    word-break: break-word;
  }
}

@media screen and (max-width: 992px) {
  .contact {
    padding: 30px 15px;
    min-height: 0;
    height: auto;
  }
  .contact-section {
    a {
      width: 50%;
    }
  }
}

.color-invert {
  filter: invert(1);
}

@mixin generate-paddings-options($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step: $i;

      .p-t-#{$step} {
        padding-top: ($step * 1px) !important;
      }
      .p-r-#{$step} {
        padding-right: ($step * 1px) !important;
      }
      .p-l-#{$step} {
        padding-left: ($step * 1px) !important;
      }
      .p-b-#{$step} {
        padding-bottom: ($step * 1px) !important;
      }
      .padding-#{$step} {
        padding: ($step * 1px) !important;
      }
    }
  }
}

@include generate-paddings-options(100);

/* Generic Margin Helpers
  ------------------------------------
   */
@mixin generate-margin-options($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step: $i;
      .m-t-#{$step} {
        margin-top: ($step * 1px) !important;
      }
      .m-r-#{$step} {
        margin-right: ($step * 1px) !important;
      }
      .m-l-#{$step} {
        margin-left: ($step * 1px) !important;
      }
      .m-b-#{$step} {
        margin-bottom: ($step * 1px) !important;
      }
    }
  }
}

@include generate-margin-options(100);

@media screen and (max-width: 768px) {
  @mixin generate-paddings-options($n, $j: 0) {
    @if $j <= $n {
      @for $i from $j through $n {
        $step: $i;

        .p-t-#{$step} {
          padding-top: ($step * 0.5px) !important;
        }
        .p-r-#{$step} {
          padding-right: ($step * 0.5px) !important;
        }
        .p-l-#{$step} {
          padding-left: ($step * 0.5px) !important;
        }
        .p-b-#{$step} {
          padding-bottom: ($step * 0.5px) !important;
        }
        .padding-#{$step} {
          padding: ($step * 0.5px) !important;
        }
      }
    }
  }

  @include generate-paddings-options(100);

  /* Generic Margin Helpers
  ------------------------------------
   */
  @mixin generate-margin-options($n, $j: 0) {
    @if $j <= $n {
      @for $i from $j through $n {
        $step: $i;
        .m-t-#{$step} {
          margin-top: ($step * 0.5px) !important;
        }
        .m-r-#{$step} {
          margin-right: ($step * 0.5px) !important;
        }
        .m-l-#{$step} {
          margin-left: ($step * 0.5px) !important;
        }
        .m-b-#{$step} {
          margin-bottom: ($step * 0.5px) !important;
        }
      }
    }
  }

  @include generate-margin-options(100);
}

@mixin generate-max-width-options($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step: $i;
      .m-w-#{$step} {
        max-width: ($step * 1%) !important;
      }
    }
  }
}

@include generate-max-width-options(100);

@mixin generate-width-options($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step: $i;
      .w-#{$step} {
        width: ($step * 1%) !important;
      }
    }
  }
}

@include generate-width-options(100);

.inquiries {
  width: 400px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  bottom: 110px;
  right: 30px;
  border-radius: 20px;
  transition: 1s;
  background: rgba(122, 122, 122, 0.05);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.6px);
  -webkit-backdrop-filter: blur(18.6px);
  border: 1px solid rgba(122, 122, 122, 0.32);
  overflow: hidden;
}

.inquiry-form {
  width: 80%;
  .form-label {
    margin-top: 20px;
    font-family: "Lexend";
    color: #dfdfdf;
    &.first-of-type {
      margin-top: 0;
    }
  }
  .form-submit {
    background-color: rgb(27, 130, 14);
    border-radius: 8px;
    font-size: 18px;
    padding: 6px 12px;
    width: auto;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: 300ms;
    margin-top: 20px;
    line-height: 24px;
    min-width: 116px;
    text-transform: capitalize;
    &:hover {
      transform: scale(1.1);
    }
    span {
      font-size: 18px;
    }
    .progress-spinner {
      width: 18px;
      color: white;
    }
  }
}

.inquiry-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(1px);
  width: 24px;
  height: 24px;
  border-radius: 6px;
  user-select: none;
  transition: 300ms;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
  span {
    color: #e8e8e8;
  }
}

.inquiry-show {
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  background-color: rgb(27, 130, 14);
  width: 54px;
  height: 54px;
  border-radius: 50%;
  user-select: none;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
  .close-chat {
    color: #fff;
    font-size: 44px;
  }
  .open-chat {
    color: #fff;
    font-size: 34px;
  }
}

.animate-spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.success-message {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16.7px);
  -webkit-backdrop-filter: blur(16.7px);
  border: 1px solid rgba(53, 53, 53, 0.72);
  padding: 30px;
  width: 90%;
  p {
    font-family: "Lexend";
    text-align: center;
    color: #dfdfdf;
  }
}

.success-alert {
  position: absolute;
  z-index: 101;
  background-color: rgba(#000, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-div {
  animation: fadeInOut 0.5s ease-in-out;
}

.rotate-y {
  transform: rotate(0deg);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media screen and (max-width: 768px) {
  .inquiries {
    width: 90%;
    height: 450px;
    top: 110px;
    right: 5%;
    left: 5%;
  }

  .inquiry-show {
    bottom: 27px;
    right: 27px;
    width: 45px;
    height: 45px;
    .close-chat {
      font-size: 30px;
    }
    .open-chat {
      color: #fff;
      font-size: 24px;
    }
  }
}
